import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import styles from "./CampusAmbassador.module.scss";
import { Card, Grid, Typography, Button, TextField } from "@mui/material";

import { useMediaQuery, useTheme } from "@mui/material";

import { SiWhatsapp } from "react-icons/si";

import UnderLine from "../../assets/images/underLine.png";
import keamNavigator from "../../assets/images/keamNavigator.jpeg";

export default function CampusAmbassador() {
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    college: "",
    branch: "",
    semester: "",
    membership: "",
  });
  console.log(formData);

  const SteinStore = require("stein-js-client");
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/66c71a1e4d11fd04f020e81e"
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.college.trim()) {
      errors.school = "College is required";
    }
    if (!formData.branch.trim()) {
      errors.school = "Branch is required";
    }
    if (!formData.semester.trim()) {
      errors.semester = "Semester is required";
    }
    if (!formData.membership.trim()) {
      errors.membership = "Data is required";
    }

    if (Object.keys(errors).length === 0) {
      setSubmitting(true);

      const dataWithDateTime = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        college: formData.college,
        branch: formData.branch,
        semester: formData.semester,
        membership: formData.membership,
        timestamp: new Date().toLocaleString(),
      };

      store
        .append("Sheet1", [dataWithDateTime])
        .then((res) => {
          console.log("Form data submitted to Google Sheets:", res);
          setSubmitted(true);
          setSubmitting(false);
          alert("Submitted Successfully");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
          setSubmitting(false);
          alert("Error submitting form data. Please try again later.");
        });
    } else {
      setErrors(errors);
    }
  };
  const handleWhatsAppRedirect = () => {
    const url = "https://chat.whatsapp.com/LPFkMMHQwxu0uoe4qjTbEu";
    window.open(url, "_blank");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const textFieldRef = useRef(null);

  useEffect(() => {
    if (isMobile) {
      setShouldFocus(true);
    } else {
      setShouldFocus(false);
    }
  }, [isMobile]);

  // Apply focus conditionally after page load
  useEffect(() => {
    // Ensure focus is applied after initial render
    const handleFocus = () => {
      if (shouldFocus && textFieldRef.current) {
        textFieldRef.current.focus();
      }
    };

    // Use setTimeout to ensure focus happens after initial render
    const timer = setTimeout(handleFocus, 100);

    return () => clearTimeout(timer);
  }, [shouldFocus]);

  return (
    <div className={styles.wrapper}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="500"
          textAlign="center">
          Campus Ambassador Program 2024
        </Typography>
        {/* <img src={UnderLine} alt="" /> */}

        <Typography
          fontFamily="Inter"
          fontSize="18px"
          fontWeight="400"
          marginTop="50px"
          textAlign="justify"
          maxWidth="1000px">
          Join MSigma Gokulam's revolution to empower engineers & engineering
          students. We are MSigma Gokulam, the ultimate destination for
          engineering students! We provide world-class courses, led by 44+ of
          nation's top brains, in GATE, IES, PSU, as well as BTech. We also
          house an R&D & IT Department. MSigma Campus Ambassador acts as a
          bridge that connects MSigma Gokulam to our students. As a two-way
          channel, informing students about our programs and courses as well as
          informing us about their queries, requests and concerns. Get paid and
          get assured gifts for each and every successful referral.
        </Typography>
        {/* <Button
        onClick={handleWhatsAppRedirect}
        type="submit"
        variant="contained"
        sx={{
          marginTop: "50px",
          height: "60px",
          backgroundColor: "#3878F2",
          color: "#E2EBFF",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#00359b",
          },
        }}>
        {" "}
        <Typography
          fontFamily="Inter"
          fontWeight="500"
          sx={{
            fontSize: {
              sm: "18px",
              md: "21px",
              lg: "22px",
            },
          }}>
          <SiWhatsapp style={{ marginRight: "5px" }} />
          Click here to join the KEAM Navigator whatsapp community
        </Typography>
      </Button> */}
      </Grid>
      <Grid container marginTop="20px" justifyContent="center">
        <Grid item xs={12} md={12}>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", justifyContent: "center" }}>
            <Card className={styles.card1}>
              <TextField
                required
                id="standard-required"
                label="Name"
                name="name"
                value={formData.name}
                variant="standard"
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
                onChange={handleInputChange}
              />

              <TextField
                required
                id="standard-required"
                label="College"
                name="college"
                value={formData.school}
                variant="standard"
                fullWidth
                error={!!errors.school}
                helperText={errors.school}
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Branch"
                name="branch"
                value={formData.branch}
                variant="standard"
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Semester"
                name="semester"
                value={formData.semester}
                variant="standard"
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                required
                id="standard-required"
                label="Email"
                name="email"
                value={formData.email}
                variant="standard"
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleInputChange}
              />

              <TextField
                required
                type="number"
                id="standard-required"
                label="Phone No"
                name="phone"
                value={formData.phone}
                variant="standard"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
                onChange={handleInputChange}
              />
              <label>
                {" "}
                <Typography
                  color="#757575"
                  sx={{
                    fontSize: { xs: "12px", sm: "1rem" },
                    marginBottom: "-25px",
                  }}>
                  Do you have membership in any professional / students
                  organisations? (IEEE, ISTE, IEDC, ASME etc.. ) *
                </Typography>
              </label>
              <TextField
                id="standard-required"
                inputLabelProps={{ shrink: true }}
                // inputRef={textFieldRef}
                // label={
                //   <Typography
                //     sx={{
                //       fontSize: { xs: "12px", sm: "1rem" },
                //     }}>
                //     {isMobile ? (
                //       <>
                //         Do you have membership in any professional / students
                //         organisations? (IEEE, ISTE, IEDC, ASME etc.. *)
                //       </>
                //     ) : (
                //       "Do you have membership in any professional / students organisations? (IEEE, ISTE, IEDC, ASME etc.. *"
                //     )}
                //   </Typography>
                // }
                name="membership"
                value={formData.membership}
                variant="standard"
                fullWidth
                onChange={handleInputChange}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  height: "60px",
                  backgroundColor: "#43BA80",
                  color: "#E2EBFF",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#3878F2",
                  },
                }}>
                <Typography
                  fontFamily="Inter"
                  fontWeight="500"
                  sx={{ fontSize: { sm: "18px", md: "21px", lg: "22px" } }}>
                  {submitting ? "Submitting..." : "Submit"}
                </Typography>
              </Button>
            </Card>
          </form>
        </Grid>
        {/* <Grid item xs={12} md={6} order={{ xs: 1, md: 2, lg: 2 }}>
          <img src={keamNavigator} alt="" width="100%" />
        </Grid> */}
      </Grid>
    </div>
  );
}
